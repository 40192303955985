<template>
  <div>
    <v-card v-if="infoCard">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-html="infoCardText"></v-card-text>
    </v-card>
    <v-toolbar v-else-if="showTitle" style="margin-bottom:1em">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <component
      v-bind:is="component"
      :sections="sections"
      :form-data="formData"
      :submit-label="submitLabel"
      :return-button="returnButton"
      @save="$emit('save')"
      @update="updateField"
      @submit="submit"
      @return="$emit('return')">
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData"></slot>
      </template>
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName"></slot>
      </template>
    </component>
  </div>
</template>
<style>
@media (max-width:959px) {
  .col-12 .v-input--selection-controls.v-input--checkbox {
    margin-top: 0
  }
}
</style>
<script>
import { reactive, computed, onBeforeMount, watch } from '@vue/composition-api'

export default {
  components: {
    Card: () => import('./sections/Card'),
    Stepper: () => import('./sections/Stepper'),
    StepperVertical: () => import('./sections/StepperVertical')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    infoCard: {
      type: Boolean,
      default: false
    },
    infoCardText: {
      type: String,
      default: ''
    },
    sectionType: {
      type: String,
      default: 'card'
    },
    sections: {
      type: Array,
      default: () => {
        return []
      }
    },
    initFormData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    returnButton: {
      type: String,
      default: ''
    },
    submitLabel: {
      type: String,
      default: 'Submit'
    }
  },
  setup (props, { root, emit }) {
    const component = computed(() => {
      switch (props.sectionType) {
        case 'card': return 'Card'
        case 'stepper': return 'Stepper'
        case 'stepper-v': return 'StepperVertical'
      }
    })
    const formData = reactive(props.initFormData || {})
    watch(() => props.initFormData, () => {
      // console.log('initFormdata updated', props.initFormData)
      for (const l in props.initFormData) {
        if (!(l in formData) || JSON.stringify(formData[l]) !== JSON.stringify(props.initFormData[l])) {
          formData[l] = props.initFormData[l]
        }
      }
    })

    function updateField ({ field, value, index, action }) {
      if (action === 'remove' && index != null) {
        // console.log('removing', { field, value, index, action })
        formData[field].splice(index, 1)
      } else if (index != null) {
        if (!(field in formData)) formData[field] = []
        while (!(index in formData[field])) formData[field].push({})
        formData[field].splice(index, 1, value)
      } else {
        formData[field] = value
      }
      emit('updatedField', { field, value, index, action })
      emit('updatedData', formData)
    }

    // function updateField ({ field, value }) {
    //   // console.log(obj)
    //   let temp = []
    //   if (field.search(' ') < 0) temp = field.split('.')
    //   let prevVal = ''
    //   if (temp.length <= 1) {
    //     if (value !== formData.value[field]) {
    //       // console.log('updating ' + field, value, formData.value[field] || 'null')
    //       prevVal = formData.value[field]
    //       formData.value[field] = value
    //     }
    //   } else if (temp.length === 2) {
    //     if (!(temp[0] in formData.value)) formData.value[temp[0]] = {}
    //     // console.log('updating ' + temp[0] + '.' + temp[1], value, formData.value[temp[0]][temp[1]] || 'null')
    //     prevVal = formData.value[temp[0]][temp[1]]
    //     formData.value[temp[0]][temp[1]] = value
    //   } else if (temp.length === 3) {
    //     let index = parseInt(temp[1])
    //     if (!(temp[0] in formData.value)) {
    //       if (isNaN(index)) formData.value[temp[0]] = {}
    //       else formData.value[temp[0]] = []
    //     }
    //     if (!(temp[1] in formData.value[temp[0]])) {
    //       if (Array.isArray(formData.value[temp[0]])) {
    //         let cap = 0
    //         while (formData.value[temp[0]].length <= index) {
    //           formData.value[temp[0]].push({})
    //           cap++
    //           if (cap > 20) break
    //         }
    //       } else {
    //         formData.value[temp[0]][temp[1]] = {}
    //       }
    //     }
    //     prevVal = null
    //     if (temp[0] in formData.value && temp[1] in formData.value[temp[0]] && temp[2] in formData.value[temp[0]][temp[1]]) prevVal = formData.value[temp[0]][temp[1]][temp[2]]
    //     formData.value[temp[0]][temp[1]][temp[2]] = value
    //   }
    //   emit('fieldUpdated', { field, value, prevVal })
    //   emit('updated', formData.value)
    // }

    onBeforeMount(() => {
      // console.log(props.initFormData)
      props.sections.forEach((section) => {
        section.inputs.forEach((input) => {
          if ('name' in input) {
            let { name } = input
            let temp = name.split('.')
            let value = ''
            if (temp.length === 1) {
              if (name in props.initFormData) {
                value = props.initFormData[name]
              }
            } else if (temp.length === 2) {
              if (temp[0] in props.initFormData && typeof (props.initFormData[temp[0]]) === 'object' && temp[1] in props.initFormData[temp[0]]) {
                value = props.initFormData[temp[0]][temp[1]]
              }
            } else if (temp.length === 3) {
              if (temp[0] in props.initFormData && typeof (props.initFormData[temp[0]]) === 'object' && temp[1] in props.initFormData[temp[0]] && typeof (props.initFormData[temp[0]][temp[1]]) === 'object' && temp[2] in props.initFormData[temp[0]][temp[1]]) {
                value = props.initFormData[temp[0]][temp[1]][temp[2]]
              }
            }
            updateField({ field: name, value })
          }
        })
      })
    })

    function submit () {
      // let data = JSON.stringify(formData.value)
      // console.log(data)
      // If any input fields have "name" properties, use that field name instead of the label name
      for (let i = 0; i < props.sections.length; i++) {
        for (let j = 0; j < props.sections[i].inputs.length; j++) {
          if ('name' in props.sections[i].inputs[j] && props.sections[i].inputs[j].label in formData) {
            let { label } = props.sections[i].inputs[j].label
            let temp = props.sections[i].inputs[j].name.split('.')
            let val = formData[label]
            switch (temp.length) {
              case 1:
                formData[temp[0]] = val
                break
              case 2:
                if (!(temp[0] in formData)) formData[temp[0]] = {}
                formData[temp[0]][temp[1]] = val
                break
              case 3:
                if (!(temp[0] in formData)) formData[temp[0]] = {}
                if (!(temp[1] in formData[temp[0]])) formData[temp[0]][temp[1]] = {}
                formData[temp[0]][temp[1]][temp[2]] = val
                break
              case 4:
                if (!(temp[0] in formData)) formData[temp[0]] = {}
                if (!(temp[1] in formData[temp[0]])) formData[temp[0]][temp[1]] = {}
                if (!(temp[2] in formData[temp[0]][temp[1]])) formData[temp[0]][temp[1]][temp[2]] = {}
                formData[temp[0]][temp[1]][temp[2]][temp[3]] = val
                break
            }
            delete formData[label]
          }
        }
      }
      emit('submit', formData)
    }

    return {
      component,
      formData,
      updateField,
      submit
    }
  }
}
</script>
